import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/components/auth/Auth.vue'
import Login from '@/components/auth/Login.vue'
import Logout from '@/components/auth/Logout.vue'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/saml/sso',
    beforeEnter() {
      location.href = process.env.VUE_APP_API_URL + 'login'
    }
  },
  {
    path: '/saml/logout',
    beforeEnter() {
      location.href = process.env.VUE_APP_API_URL + '/saml/logout'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    // meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: '/auth/:session_id',
    name: 'auth',
    component: Auth,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Portfolio.vue'),
    // meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: '/opportunity/create',
    name: 'create_opportunity',
    component: () => import(/* webpackChunkName: "about" */ '@/views/CreateOpportunity.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/opportunity/create/:clone',
    name: 'clone_opportunity',
    component: () => import(/* webpackChunkName: "about" */ '@/views/OpportunityClone.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/opportunity/list',
    name: 'opportunity_list',
    component: () => import(/* webpackChunkName: "about" */ '@/views/OpportunityList.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/opportunity/:id',
    name: 'view_opportunity',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Opportunity.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/opportunity/:id/edit',
    name: 'edit_opportunity',
    component: () => import(/* webpackChunkName: "about" */ '@/views/OpportunityEdit.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/opportunity/:id/feedback',
    name: 'feedback_opportunity',
    component: () => import(/* webpackChunkName: "about" */ '@/views/OpportunityFeedback.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/opportunity/:id/scenario/create',
    name: 'CreateOpportunityScenario',
    component: () => import(/* webpackChunkName: "about" */ '@/views/CreateOpportunityScenario.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/opportunity/:id/scenario/create/:clone',
    name: 'CloneOpportunityScenario',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ScenarioClone.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/scenario/:id/edit',
    name: 'EditOpportunityScenario',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ScenarioEdit.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/assessment/compare',
    name: 'CompareResults',
    component: () => import(/* webpackChunkName: "about" */ '@/views/CompareResults.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/assessment/compare/:id',
    name: 'CompareResults',
    component: () => import(/* webpackChunkName: "about" */ '@/views/CompareResults.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/assessment/:id',
    name: 'view_assessment',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ViewAssessment.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]

export default routes
