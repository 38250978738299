import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
// environment variable package
require("dotenv").config();
axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Referrer"] = "https://localhost:8080/";
// axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
// axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET,HEAD,OPTIONS,POST,PUT";
// axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization";

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("access_token") || null,
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token;
    },
    destroyToken(state) {
      state.token = null;
    },
    get() {},
    post() {},
    put() {},
    createOpportunity() {},
    getCompanies() {},
    getOpportunity() {},
    editOpportunity() {},
    feedbackOpportunity() {},
    deleteOpportunity() {},
    createScenario() {},
    getScenario() {},
    listScenario() {},
    editScenario() {},
    deleteScenario() {},
    createAssessment() {},
    getAssessment() {},
    compareAssessment() {},
    exportData() {},
  },
  actions: {
    retrieveToken(context, session_id) {
      return new Promise((resolve, reject) => {
        // send an authentication request to the server
        axios
          .post("/token/" + session_id, {})
          .then((response) => {
            // if successful, store generated token locally
            console.log(response)
            const token = response.data.token;

            localStorage.setItem("access_token", token);
            context.commit("retrieveToken", token);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    overrideToken(context, credentials) {
      return new Promise((resolve, reject) => {
        // send an authentication request to the server
        axios
          .post("/login/override", {}, {
              auth: {
                username: credentials.username,
                password: credentials.password,
              },
            })
          .then((response) => {
            // if successful, store generated token locally
            console.log(response)
            const token = response.data.token;

            localStorage.setItem("access_token", token);
            context.commit("retrieveToken", token);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    validateToken(context) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      return new Promise((resolve, reject) => {
        axios
          .get("/validate")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            localStorage.removeItem("access_token");
            context.commit("destroyToken");

            reject(error);
          });
      });
    },
    destroyToken(context) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          localStorage.removeItem("access_token");
          context.commit("destroyToken");

          axios
            .post("/logout")
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    post(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;
      console.log("post...")
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post(data.url, data.data)
            .then((response) => {
              context.commit("post", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    get(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get(data.url, data.data)
            .then((response) => {
              context.commit("get", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    put(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .put(data.url, data.data)
            .then((response) => {
              context.commit("put", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    createOpportunity(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/opportunity/create", {
              data: data.data,
            })
            .then((response) => {
              context.commit("createOpportunity", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    getCompanies(context) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get("/opportunity/company", {})
            .then((response) => {
              context.commit("getCompanies", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    getOpportunity(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get("/opportunity/" + data.data, {})
            .then((response) => {
              context.commit("getOpportunity", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    listOpportunity(context) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get("/opportunity", {})
            .then((response) => {
              context.commit("listOpportunity", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    editOpportunity(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/opportunity/edit", {
              data: data.data,
              id: data.id,
            })
            .then((response) => {
              context.commit("editOpportunity", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    feedbackOpportunity(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/opportunity/feedback", {
              data: data.data,
              id: data.id,
            })
            .then((response) => {
              context.commit("feedbackOpportunity", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    deleteOpportunity(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .delete("/opportunity/" + data.id)
            .then((response) => {
              context.commit("deleteOpportunity", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    createScenario(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/opportunity/" + data.id + "/scenario/create", {
              data: data.data,
            })
            .then((response) => {
              context.commit("createScenario", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              if (!error.response) {
                console.log("There was a problem in the server.");
              } else if (error.response.status === 500) {
                console.log("There was some error in the calculations.");
              }
              reject(error);
            });
        });
      }
    },
    createScenarioDisqualified(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/opportunity/" + data.id + "/scenario/create/disqualified", {
              data: data.data,
            })
            .then((response) => {
              context.commit("createScenarioDisqualified", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              if (!error.response) {
                console.log("There was a problem in the server.");
              } else if (error.response.status === 500) {
                console.log("There was some error in the calculations.");
              }
              reject(error);
            });
        });
      }
    },
    getScenario(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get("/scenario/" + data.data, {})
            .then((response) => {
              context.commit("getScenario", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    editScenario(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/scenario/" + data.id + "/edit", {
              data: data.data,
            })
            .then((response) => {
              context.commit("editScenario", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    editScenarioDisqualified(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/scenario/" + data.id + "/edit/disqualified", {
              data: data.data,
            })
            .then((response) => {
              context.commit("editScenario", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    deleteScenario(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .delete("/scenario/" + data.id)
            .then((response) => {
              context.commit("deleteScenario", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    createAssessment(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          console.log(data);
          axios
            .post("/opportunity/scenario/" + data.id + "/assess", {
              data: data.report_year,
            })
            .then((response) => {
              context.commit("createAssessment", response.data);
              resolve(response);
            })
            .catch((error) => {
              if (!error.response) {
                console.log("There was a problem in the server.");
              } else if (error.status === 500) {
                console.log("There was some error in the calculations.");
              }
              reject(error);
            });
        });
      }
    },
    getAssessment(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get("/assessment/" + data.data, {})
            .then((response) => {
              context.commit("getAssessment", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
    compareAssessment(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/assessment/compare", {
              data: data.data,
            })
            .then((response) => {
              context.commit("compareAssessment", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    }, 
    exportData(context) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;
      axios.defaults.headers.common["Cache-Control"] = 'no-cache';

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get("/export", {
              responseType: 'blob'
            })
            .then((response) => {
              context.commit("exportData", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
     sendFeedback(context, data) {
      axios.defaults.headers.common["x-access-token"] = context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post("/feedback", {
              data: data.data
            })
            .then((response) => {
              context.commit("sendFeedback", response.data);
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    },
  },
  modules: {},
});
