import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

// axios.interceptors.response.use((response) => {
//   return response

// }, (error) => {

//   if (!error.response) {
//     router.push({
//       name: 'login',
//       params: {
//         "error": "You were logged out due to a problem with the server."
//       }
//     })
//   } else if (error.status === 401) {
//     router.push({
//       name: 'login',
//       params: {
//         "error": "Your login has expired."
//       }
//     })
//   }

//   return Promise.reject(error)
// })

const router = new VueRouter({
  routes,
  mode: "history",
});

// protect sensitive material by redirecting to login page
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    return new Promise((resolve, reject) => {

      store.dispatch("validateToken").then((response) => {
        next()
        resolve(response)

      }).catch((error) => {

        if (!error.response) {
          next({
            name: 'login',
            params: {
              "error": "You were logged out due to a problem with the server."
            }
          })
        } else if (error.response.status === 401) {
          next({
            name: 'login',
            params: {
              "error": "Your login session has expired."
            }
          })
        } else {
          next({
            name: "login",
            params: {
              "error": "Your were logged out unexpectedly."
            }
          });
        }
        reject(error)
      })
    })

  } else {
    next();

  }

});

const DEFAULT_TITLE = 'Strategic Economic Impact Model - Invest Victoria';
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
