<template>
  <div>
    <PageTitle
      variant="img"
      text="The Strategic Economic Impact Model (SEIM) is a tool which supports Invest Victoria in evaluating individual company foreign direct investment projects on their alignment with the International Investment Strategy."
    >
      <b-img fluid src="@/assets/seim-logo.png" />
    </PageTitle>
    <b-container>
      <b-jumbotron bg-variant="white">
        <!-- <template v-slot:header>Welcome</template> -->

        <template v-slot:lead>
          <b-row align-v="center">
            <b-col cols="4">
              <br />
              <br />
              <p>
                The tool refers to broad project proposals as opportunities, and
                variations within each opportunity as scenarios. That is, a
                single opportunity will have at least one scenario, but possibly
                more. Project opportunities and scenarios can be created,
                edited, deleted, and compared against each other.
              </p>
              <a href="SEIM User Guide.pdf" download>
                <b-button variant="primary">
                  Download User Guide
                  <b-icon-file-earmark-arrow-down
                    shift-h="3px"
                    scale=".9"
                    shift-v="2px"
                  />
                </b-button>
              </a>
              <!-- <b-icon-blank />
              <b-button variant="sgs-purple" @click="export_data">
                Export Data
                <b-icon-box-arrow-down shift-h="3px" scale=".9" shift-v="2px" />
              </b-button> -->
            </b-col>
            <b-col>
              <b-img fluid src="@/assets/diagram.png" />
            </b-col>
          </b-row>
        </template>
        <!-- 
        <hr class="my-4" />
        <b-row>
          <b-col>
            <p>
              Once a scenario is created, information will be required across
              six dimensions:
            </p>
            <ul>
              <li>
                <b>Sector, location, and capabilities alignment</b> with the
                investment objectives of Invest Victoria
              </li>
              <li>
                <b>Brand Impact</b> on Victoria with respect to attracting
                global firms, headquarter offices, and innovative activities
              </li>
              <li>
                <b>Innovation</b> in the form of R&D activity, development of
                new products, or patent generation
              </li>
              <li>
                <b>Economic Impact</b> in terms of increased gross state product
                (GSP), additional jobs, and increased taxation revenue
              </li>
              <li>
                <b>Export Impact</b> in the form of the value and diversity of
                exports from Victoria
              </li>
              <li>
                <b>Cost</b> of both the value of IAAP grant funding and
                facilitation required by the scenario
              </li>
            </ul>
          </b-col>
          <b-col>
            <p>
              The information provided may be a broad estimate, available during
              early scoping, to more detailed estimates following further
              engagement.
            </p>
            <p>
              Using a multi criteria assessment framework, the SEIM will
              consider the merits of each scenario across these dimensions. The
              aggregate performance of the scenario along with descriptive
              information and key performance measures (e.g. BP3 metrics) can be
              accessed through the ‘View’ page of the tool. The ‘Scenario
              Comparison’ page facilitates the comparison of multiple scenarios
              based on their aggregate performance across the six dimensions.
            </p>
            <p>
              Note that while the SEIM will assist in shortlisting and ranking
              opportunities, proceeding to funding, especially for large/complex
              proposals, will likely require additional analysis and the
              development of a CBA.
            </p>
          </b-col>
         <b-col fluid>
            <b-jumbotron height="1000px" align="center">
              <template v-slot:header>
                <br />
                <b-icon-download variant="sgs-light" />
              </template>
              <template v-slot:lead>
                <div>Download report</div>
                <br />
                <br />
                <br />
                <br />
              </template>
            </b-jumbotron>
          </b-col>
        </b-row> 
        -->
      </b-jumbotron>
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "home",
  components: { PageTitle },
  methods: {
    export_data() {
      this.$store
        .dispatch("exportData")
        .then((response) => {
          this.loading = false;
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ivseim-db.zip");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.jumbotron {
  padding: 1em 0.5em;
}
/* p,
ul {
  font-weight: 300;
} */
</style>
