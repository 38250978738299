<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-wrap />
  </div>
</template>

<script>
export default {
  name: "auth",
  props: {},
  data() {
    return {
      loading: false,
      serverError: "",
      reponse: "",
    };
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("retrieveToken", this.$route.params.session_id)
      .then(() => {
        this.loading = false;
        this.$router.push({ name: "home" });
      })
      .catch((error) => {
        this.serverError = error.response.data;
      });
  },
};
</script>

<style scoped>
</style>
