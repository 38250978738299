<template>
  <div>
    <b-navbar v-if="loggedIn" variant="sgs-dark" sticky>
      <b-container>
        <b-container>
          <b-navbar-nav>
            <b-nav-item href="/">Home</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-badge variant="sgs-red">
              <b-nav-item href="/opportunity/create"> Create </b-nav-item>
            </b-badge>
            <b-badge variant="sgs-green">
              <b-nav-item href="/opportunity/list">View</b-nav-item>
            </b-badge>
            <b-badge variant="sgs-blue">
              <b-nav-item href="/assessment/compare">Compare</b-nav-item>
            </b-badge>
            <b-badge variant="sgs-orange">
              <b-nav-item href="/portfolio">Portfolio</b-nav-item>
            </b-badge>
            <b-nav-item>
              <b-icon-info-circle
                v-b-tooltip.hover.bottom-left.html="
                  `<b>Version 4.1</b> <br /> Released: 2 June 2021`
                "
              />
            </b-nav-item>
            <b-nav-item href="/saml/logout">Log out</b-nav-item>
          </b-navbar-nav>
        </b-container>
      </b-container>
    </b-navbar>
    <div id="content">
      <router-view />
    </div>
    <footer class="justify-content-center">
      <b-container class="text-center">
        <hr />
        <b-row align-v="center" style="padding-top: 10px">
          <b-col cols="2" class="text-left logo">
            <b-link href="https://www.invest.vic.gov.au/">
              <b-img src="@/assets/invest-victoria-logo.png" height="50px" />
            </b-link>
          </b-col>
          <b-col class="text-center">
            <p class="acknowledgement_of_country">
              We acknowledge and pay respect to the Traditional Owners of the
              country on which we work.
            </p>
          </b-col>
          <b-col cols="2" class="text-right logo">
            <b-link href="https://www.sgsep.com.au">
              <b-img src="@/assets/sgs-bcorp.png" height="55px" />
            </b-link>
          </b-col>
        </b-row>
        <br />
        <br />
      </b-container>
    </footer>
  </div>
</template>

<script>
import "@/scss/_override.scss";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-nav {
  .nav-link {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  .badge {
    margin: 0.4em 0.5em;
    border-radius: 0px;
    min-width: 12em;
    height: 2.5em;
    opacity: 0.95;

    &:hover {
      opacity: 1;
    }

    .nav-link {
      padding: 0.28em;
      font-size: 1rem;
      font-weight: 400;
      font-optical-sizing: auto;
      color: rgba(255, 255, 255, 0.9);
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

#content {
  overflow: auto;
  min-height: 38.6em;
  padding-bottom: 3em;
}
.acknowledgement_of_country {
  padding-top: 15px;
  font-size: 11pt;
  font-weight: 400;
}
.logo {
  padding: 0px 30px;
}
@media print {
  footer {
    display: none;
  }
}
</style>

<!-- Global styles -->
<style lang="scss">
@media (min-width: 1600px) {
  .container {
    max-width: 1550px;
  }
}
@media (min-width: 1300px) {
  .container {
    max-width: 1250px;
  }
}
form {
  .form-row {
    legend.col-4 {
      padding-right: 2em;
    }
  }
}
</style>
