<template>
  <div id="login">
    <br />
    <br />
    <b-card
      img-top
      class="text-center"
      bg-variant="light"
      style="max-width: 80rem"
    >
      <template v-slot:header>
        <b-img
          blank-color="rgb(50, 63, 72)"
          src="@/assets/seim-logo.png"
          style="max-height: 300px; margin-top: 0.8em"
        />
      </template>
      <div v-if="!sgs_login">
        <b-card-title>Invest Victoria</b-card-title>
        <b-card-subtitle>Strategic Economic Impact Model</b-card-subtitle>
        <br />
        <br />
        <b-form @submit.prevent="login">
          <b-button variant="primary" type="submit">Log-in via SSO</b-button>
        </b-form>
        <br />
        <a @click="sgs_login = !sgs_login">
          <h6 class="no-print help-text" style="color: #868e96">
            Administrator Log in &rarr;
          </h6>
        </a>
      </div>
      <div v-if="sgs_login">
        <b-card-title>SGS Economics & Planning</b-card-title>
        <b-card-subtitle>Strategic Economic Impact Model</b-card-subtitle>
        <!-- <form> -->
        <br />
        <br />
        <div class="row align-items-center">
          <div class="col-4">
            <label for="input-username" class="form-label">
              Username
            </label>
          </div>
          <div class="col-auto">
            <input
              v-model="username"
              type="text"
              class="form-control"
              id="input-username"
            />
          </div>
        </div>
        <br />
        <div class="row align-items-center">
          <div class="col-4">
            <label for="input-password" class="form-label">
              Password
            </label>
          </div>
          <div class="col-auto">
            <input
              v-model="password"
              type="password"
              class="form-control"
              id="input-password"
            />
          </div>
        </div>
        <br />
        <div class="row align-items-center">
          <div class="col">
            <button @click="loginAdmin" class="btn btn-block btn-secondary">
              Submit
            </button>
          </div>
        </div>
        <br />
        <a>
          <h5 class="no-print help-text" @click="sgs_login = !sgs_login">
            &larr; Back
          </h5>
        </a>
      </div>
    </b-card>
    <br />
    <b-alert v-if="successMessage" class="text-center" variant="success" show>{{
      successMessage
    }}</b-alert>
    <b-alert v-if="serverError" show variant="danger" class="text-center">
      {{ serverError }}
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "login",
  props: {
    dataSuccessMessage: {
      type: String,
    },
  },
  data() {
    return {
      sgs_login: false,
      username: "",
      password: "",
      serverError: "",
      successMessage: this.dataSuccessMessage,
      loading: false,
    };
  },
  methods: {
    login() {
      this.$router.push("/saml/sso");
    },
    loginAdmin() {
      this.$store
        .dispatch("overrideToken", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.serverError = error.response.data;
        });
    },
  },
  mounted() {
    this.serverError = this.$route.params.error;
  },
};
</script>

<style scoped>
#login {
  margin: auto;
  width: 400px;
  /* position: absolute; */
  /* top: 45%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.server-error {
  color: red;
}

.card-header {
  background-color: rgb(50, 63, 72);
}

.help-text {
  font-size: 12px;
  margin: 1em 0;
  font-weight: 700;
}
</style>
