<template>
  <div class="hanging-bar">
    <b-container>
      <br />
      <b-row align-v="center">
        <b-col cols="4" class="ml-2 text-center text-light font-weight-lighter">
          <div :class="`title ${variant}`">{{ title }}<slot></slot></div>
        </b-col>
        <b-col
          class="text-right text-light font-weight-light title-text"
          v-html="text"
        >
          <!-- {{ text }} -->
        </b-col>
      </b-row>
      <br />
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: ["title", "text", "variant", "img"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colours.scss";

.hanging-bar {
  width: 100%;
  background: rgb(50, 63, 72);
  margin-bottom: 1.5em;
}
.title {
  margin-left: 50px;
  height: 300px;
  width: 300px;
  border: 8px solid;
  vertical-align: middle;
  display: table-cell;
  font-size: 60px;
  // font-weight: 200;
  //
  color: $white;

  &.img {
    border-style: none;
  }
  &.sgs-red {
    border-color: $sgs-red;
  }
  &.sgs-blue {
    border-color: $sgs-blue;
  }
  &.sgs-green {
    border-color: $sgs-green;
  }
  &.sgs-orange {
    border-color: $sgs-orange;
  }
}
.title-text {
  font-size: 18pt;
  text-align: right;
  vertical-align: middle;
  display: table-cell;
  color: $white;
}
</style>
